
import { getCountry } from './geolocation';
let src = "//cdn.callrail.com/companies/725006065/8dfe1e09ea0750637eaa/12/swap.js";

// currently added only for US
export async function addChatWidget() {
    try {
        let country = await getCountry();
        if (country=="US") {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = src;
          script.async = true;
          document.head.appendChild(script);
    
          script.onload = () => {
            console.log(`Chat widget loaded successfully`);
          };
    
          script.onerror = () => {
            console.error(`Failed to load chat widget`);
          };
        }
    }
    catch (error) {
        console.error('An error occurred in addChatWidget:', error);
        return "NA";
    }
}
