<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="main-hero w1170">
      <div class="main-txt-wrap">
        <img src="@/assets/graphs-mobile-4.svg" class="mob-ani-1" alt="Sela Graphs" />
        <img src="@/assets/graphs-mobile-2.svg" class="mob-ani-2" alt="Sela Graphs" />
        <h1 class="txt54">Everyone Clouds.<br />Leaders Cloud Better.</h1>
        <p class="txt20 mb30px">
          <b>{{ country }}</b> Get the most out of your cloud(s) with tailored managed services and
          skilling, unbiased consulting, and empowering 360 support.
        </p>

        <!--
        <router-link
          to="/contact"
          class="btn"
        >Get Started <img
            src="@/assets/play.svg"
            alt="Play"
          /> </router-link>
        -->
        <!-- <img
          src="@/assets/graphs-mobile-3.svg"
          class="mob-ani-3"
        />
        <div class="mobile-main-animation">
      </div> -->

      </div>


      <!-- <img
        src="@/assets/graphs-row.svg"
        class="main-ani full-w"
        alt=""
      /> -->
    </div>

    <div class="home-carousel-wrapper">
      <carousel-3d :autoplay="true" :autoplay-timeout="4000" :display="5" :height="470" :width="350" :space="400"
        :perspective="0" :autoplayHoverPause="true" :disable3d="true">
        <slide v-for="(image, i) in $local.homePage[0].carouselItems.filter(img => img.isShown === true)" :index="i"
          :key="i" style="">
          <router-link :to="buildPath(image.uRLPath)">
            <div class="slide-content">
              <img style="width: 300px;" :data-index="index" :class="`${imgClass(image.borderColor.value)} imgSize`"
                :src="image.image.imageURL">
              <div :class="`${image.borderColor.value}-slide-frame slide-frame-position-size ${framePosition[i]}`">
              </div>
            </div>
          </router-link>
          <div class="btn-box-loc-home-wrapper">
            <router-link :to="buildPath(image.uRLPath)" class="btn-box-loc-home">{{ image.title }}</router-link>
          </div>
        </slide>
      </carousel-3d>
    </div>

    <h2 class="txt54 w1170 mb60px center mt60px grow-head">
      Cloud as Fast as You Grow
    </h2>
    <div class="w1170"></div>
    <div class="tabs w1170">
      <div class="tabs-btns-wrap">
        <p class="tab-btn border-yellow" @click="tab = 1" :class="{ 'tab-active': tab === 1 }">
          Managed Services
        </p>
        <p class="tab-btn border-pink" @click="tab = 2" :class="{ 'tab-active': tab === 2 }">
          Cloud(s) Skilling
        </p>
        <p class="tab-btn border-blue" @click="tab = 3" :class="{ 'tab-active': tab === 3 }">
          360 Support
        </p>
      </div>
      <div class="tabs-cont-wrap">
        <div class="tab-cont" :class="{ tabactive: tab === 1 }">
          <img src="@/assets/tech5small.jpg" class="tab-img" alt="Tech" />
          <div class="tab-cont-txt--box yellow">
            <h3 class="tab-title">Managed Services</h3>
            <p class="tab-big-txt">
              Unbiased multi-cloud solutions and expert advisory tailored for
              agile performance and cloud economics
            </p>
            <div>
              <router-link :to="buildPath('/solutions/finops-as-a-service')" class="chip">Cloud economics
                (FinOps)</router-link>
              <router-link :to="buildPath('/solutions/cloud-migration')" class="chip">Cloud migration</router-link>
              <router-link :to="buildPath('/solutions/cloud-architecture')" class="chip">Cloud
                architecture</router-link>
              <router-link :to="buildPath('/solutions/devops-as-a-service')" class="chip">Cloud Devops
                (CI/CD)</router-link>
              <router-link :to="buildPath('/solutions/security')" class="chip">Cloud security</router-link>
              <router-link :to="buildPath('/solutions/cloud-ai')" class="chip">Cloud Data and AI</router-link>
            </div>
          </div>
        </div>
        <div class="tab-cont" :class="{ tabactive: tab === 2 }">
          <img src="@/assets/tech8small.jpg" class="tab-img" alt="Servers" />
          <div class="tab-cont-txt--box pink">
            <h3 class="tab-title">Cloud(s) Skilling</h3>
            <p class="tab-big-txt">
              We cloud-skill our clients’ teams, our teams working at client
              offices and independent talent to keep them at the cutting edge
            </p>
            <div>
              <router-link :to="buildPath('/skilling#new')" class="chip">New skilling</router-link>
              <router-link :to="buildPath('/skilling#up')" class="chip">Upskilling</router-link>
              <router-link :to="buildPath('/skilling#Continuous')" class="chip">Continuous skilling</router-link>
            </div>
          </div>
        </div>
        <div class="tab-cont" :class="{ tabactive: tab === 3 }">
          <img src="@/assets/tech6small.jpg" class="tab-img" alt="Data lines" />
          <div class="tab-cont-txt--box">
            <h3 class="tab-title">Empowering 360 Support</h3>
            <p class="tab-big-txt">
              across all cloud operations, technologies and vendors
            </p>
            <p class="tab-small-txt">
              Your success manager handles all your multi-vendor relations and
              is dedicated to your performance, optimization and 24/7 support
            </p>
            <div>
              <router-link to="" class="chip not">Rapid trouble shooting</router-link>
              <router-link to="" class="chip not">Problem-solving solutions</router-link>
              <router-link :to="buildPath('/support/tailored-skilling-programs')" class="chip">Tailored skilling
                programs</router-link>
              <router-link to="" class="chip not">Technical integrations</router-link>
              <router-link :to="buildPath('/support/cloud-support')" class="chip">24/7 multi-vendor
                support</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec">
      <p class="txt30 center lighter">Trusted by</p>
      <logoscar></logoscar>
    </div>
    <div class="partners w1170 sec">
      <div class="part-tite-wrap">
        <h3>Our Partners</h3>
      </div>
      <div class="partners-wrap">
        <component :is="vendor.internal ? 'router-link' : 'div'" :to="buildPath(`${vendor.slug}`)" class="part-logo"
          v-for="vendor in $local.vendors.filter(c => c.internal)" :key="vendor._id">
          <img :src="vendor.mSPBadge.imageURL" :alt="vendor.name" />
        </component>
      </div>
    </div>




    <div class="info-sec friend">
      <div class="w1170">
        <div class="friend-sec-line"></div>
        <h3 class="txt54">Proud to Be Friends With</h3>
      </div>
      <div class="info-wrap w1170">
        <a :href="friend.link" target="_blank" v-for="friend in $local.websiteSettings.friends" :key="friend._id"
          class="friend-link">
          <img :src="friend.logo.imageURL">

        </a>

      </div>
    </div>





    <div class="info-sec">
      <div class="w1170">
        <h3 class="txt54">Sela is Your Trusted Advisor</h3>
      </div>
      <div class="info-wrap w1170">
        <div v-for="advisor in $local.websiteSettings.advisorNumbers" :key="advisor._id" class="info-box">
          <rolling-number class="big-num" :value="advisor.number" :suffix="advisor.suffix" />
          <p class="info-txt">{{ advisor.text }}</p>
        </div>

      </div>
    </div>
    <div class="w1000">
      <h3 class="txt54 mb60px center mt60px">Beyond Cloud</h3>
      <div class="tab-big">
        <div class="side-tab">

          <!-- 
          <div
            class="tab blue-line"
            @click="bigTab = 1"
            :class="{ active: bigTab == 1 }"
          >
            <h4 class="tab-title">Delivery</h4>
            <p>Professional Services</p>
          </div>
          <div
            class="tab"
            @click="bigTab = 2"
            :class="{ active: bigTab == 2 }"
          >
            <h4 class="tab-title">Skilling</h4>
            <p>Training Services</p>
          </div>
      -->

          <div class="tab blue-line" @click="bigTab = 1" :class="{ active: bigTab == 1 }">
            <h4 class="tab-title">360 Support</h4>
            <p>Support Services</p>
          </div>
        </div>
        <div class="tabbed-area">
          <div class="tabbed-content" v-if="bigTab == 1">
            <p class="mb60px">
              <strong>Empowering 360 Support across all cloud operations, technologies and vendors</strong>
              <br />
              Your success manager is dedicated to your performance, optimization, multi-vendor relations, and 24/7
              support.
            </p>

            <div>
              <h4 class="txt30">Unmatched Support 24/7</h4>
              <a href="stats" class="support-link" title="Click here to see our support numbers">
                <div class="support-button">
                  <div>

                    <p class="num-link-txt">Click here to see our support numbers</p>
                  </div><img src="/img/num.4b7eb4ae.png" class="num-link-num">
                </div>
              </a>
            </div>
            <br /><br />
            <h4 class="txt30">Support Services</h4>

            <div class="icon-drop-items">
              <div to="/solutions" class="icon-drop-item">
                <img src="@/assets/blue-Services-icon-2.svg" alt="Customer Success" />
                <p class="icon-drop-small-title">Customer Success</p>
                <p class="icon-drop-txt">
                  From onboarding and during the full cycle of your journey as our cloud customer, you will be assigned
                  a
                  customer success person to serve as your one focal point in the organization, and take care for all
                  your
                  needs.
                </p>
              </div>

              <div class="icon-drop-item">
                <img src="@/assets/blue-Group 668.svg" alt="Managed Account" />
                <p class="icon-drop-small-title">Managed Account</p>
                <p class="icon-drop-txt">You’re never alone to manage your cloud accounts again. We help to create and
                  then manage all definitions, user management and accessibility with your own customer portal where you
                  will have a single transparent view for all your multi-cloud account needs.</p>
              </div>

              <div class="icon-drop-item">
                <img src="@/assets/blue-Group 185.svg" alt="Multi-Vendor relationship management" />
                <p class="icon-drop-small-title">Multi-Vendor relationship management</p>
                <p class="icon-drop-txt">Vendor relationships are complex. Each vendor is structured differently with
                  their own programs, functions, policy and support needs. We serve as your representative with the
                  vendor
                  so your needs are always taken care of, and to ensure that you get the most out of your clouds.</p>
              </div>

              <div class="icon-drop-item">
                <img src="@/assets/blue-Group 670.svg" alt="Cloud Support" />
                <p class="icon-drop-small-title">Cloud Support</p>
                <p class="icon-drop-txt">
                  We provide 24/7 cloud support to solve any cloud-related issues within a given SLA. We provide basic
                  tier (free) support for all customers, with a range of broader support service tiers to suit each
                  clients’ needs & team.
                </p>
              </div>
              <div class="icon-drop-item">
                <img src="@/assets/blue-Services-icon-1.svg" alt="FinOps" />
                <p class="icon-drop-small-title">FinOps</p>
                <p class="icon-drop-txt">Our dedicated FinOps team will help you utilize your cloud in the best economic
                  way that will suit you needs and pocket. FinOps tool is provided free to all clients to optimize your
                  cloud economics. You will have access to actual data to monitor you expenses with actionable insights
                  to
                  attain cost-effective cloud computing while maintaining service flexibility and performance.</p>
              </div>

              <div class="icon-drop-item">
                <img src="@/assets/blue-Services-icon-1.svg" alt="Workload Optimization" />
                <p class="icon-drop-small-title">Workload Optimization</p>
                <p class="icon-drop-txt">A critical aspect of cloud economics, we provide workload optimization support
                  as
                  part of our core service, at no extra charge. We help every client technically review your workloads
                  and
                  provide optimization recommendations that can reduce your costs while keeping your optimal quality of
                  service.</p>
              </div>
              <div class="icon-drop-item">
                <img src="@/assets/blue-Services-icon-1.svg" alt="Architecture Review" />
                <p class="icon-drop-small-title">Architecture Review</p>
                <p class="icon-drop-txt">Our Cloud Architects provide a periodic review of your architecture to make
                  sure
                  that your solution is using the current best practices both technologically and for security.</p>
              </div>
              <div class="icon-drop-item">
                <img src="@/assets/blue-Services-icon-1.svg" alt="Tailored skilling programs" />
                <p class="icon-drop-small-title">Tailored skilling programs</p>
                <p class="icon-drop-txt">Our skilling experts will analyze your team skilling needs with you and provide
                  suggested skilling programs. We provide an extensive range of on-site tailored Skilling for our
                  clients.
                  In addition, as a cloud customer, you will enjoy discounts and credits for Sela Skilling in Sela
                  public
                  courses and conferences.</p>
              </div>

            </div>
            <br />




          </div>
        </div>
      </div>
    </div>
    <div class="clients">
      <p class="clients-head">
        Don't Take Our Word for It,<br />Here's What Our Clients Say
      </p>
      <div class="clients-frame">
        <testimonials :showDots=true :showLogo=true></testimonials>
      </div>
    </div>
    <div class="end-sec">
      <p class="txt40">
        We build, nurture and optimize clouds, and hold them accountable.
      </p>
      <p class="txt40 bold">So you don’t need to.</p>
      <router-link to="contact" type="button" class="btn mt30px">Talk to an expert
      </router-link>
    </div>
    <div class="events-sec" v-if="futureEvents.length">
      <div class="event-sec-line"></div>
      <h4 class="txt54 center mb60px">Upcoming Events</h4>
      <div class="events-wrap">
        <linkpath v-for="event in futureEvents.slice(0, 2)" :key="event._id"
          :to="event.externalLink || buildPath(`event/${event.slug}`)" class="event-box">
          <img :src="event.thumbnail.imageURL || event.cover.imageURL" class="event-box-img" :alt="event.title" />
          <div class="event-box-txt-wrap">
            <div class="event-box-date">
              <p class="event-box-day">{{ new Date(event.date).getDate() }}</p>
              <p class="event-box-mon">
                {{
                  new Date(event.date).toLocaleString("en-US", {
                    month: "short",
                  })
                }}
              </p>
              <p class="event-box-year">
                {{ new Date(event.date).getFullYear() }}
              </p>
            </div>
            <p class="event-box-item">{{ event.title }}</p>
            <div class="event-info-box">
              <p>{{ event.blurb }}</p>
            </div>
          </div>
        </linkpath>
      </div>
      <router-link to="events" type="button" class="btn lowcase mb60px">View all Events
      </router-link>
    </div>
    <!-- <div class="faq">
      <div class="faq-head-wrap">
                <div class="faq-frame"></div>
                <h3 class="faq-head">FAQ</h3>
                <div class="faqs-list">
                    <faq></faq>
                    <faq></faq>
                    <faq></faq>
                    <faq></faq>
                    <faq></faq>
                </div>
            </div>
    </div> -->
    <awards></awards>
    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Testimonials from "@/components/Testimonials.vue";
import Logoscar from "@/components/Logoscar.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import Awards from "@/components/Awards.vue";
import Linkpath from "@/components/Linkpath.vue";
import RollingNumber from "@/components/rollingNumber.vue";
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { buildPath } from "@/utils/buildPath";
import { addChatWidget } from "../utils/addChatWidget";
import { getCountryFull } from "../utils/geolocation";

export default {
  props: {
    country: String,
    countryFull: String
  },
  components: {
    Topnav,
    Botfooter,
    Logoscar,
    Testimonials,
    Awards,
    Linkpath,
    RollingNumber,
    Carousel3d,
    Slide,
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.$local.homePage[0].metaTitle || "Multi-Cloud Services and Solutions",
        description: this.$local.homePage[0].metaDescription,
      }),
    };
  },
  // image: "https://sfo2.digitaloceanspaces.com/bagel/sela/og.jpg",
  // description:
  // "Unbiased multi-cloud solutions, expert advisory & managed services & skilling, unbiased consulting, and empowering 360 support.",
  computed: {
    futureEvents() {
      function sortFuture(a, b) {
        return new Date(a.date) - new Date(b.date);
      }
      let retArr = this.$local.events;
      if (this.countryFull) { // visitors from the US/IN/IL should only see events relevant to them
        retArr = retArr.filter((e) => e.branche && e.branche[0] && e.branche[0].value && e.branche[0].value.includes(this.countryFull));
      }
      return retArr.filter((e) => new Date(e.date) > new Date() && !e.draft).sort(sortFuture);
    },
  },
  data: () => ({
    tab: 1,
    bigTab: 1,
    framePosition: [
      'top-right',
      'bottom-right',
      'bottom-left'
    ],
  }),
  name: "Home",
  methods: {
    buildPath,
    imgClass(frameColor) {
      if (frameColor === 'light-blue')
        return 'imgUpR';
      if (frameColor === 'dark-blue')
        return 'imgUpL';
      return 'imgReg';
    },
    async initData() {
      this.countryFull = await getCountryFull();
    },   
  },
  created() {
    this.initData()
  },
  async mounted() {
    addChatWidget();
  }
};
</script>
<style>
.carousel-3d-custom .slide {
  background: none !important;
  box-shadow: none !important;
}

.carousel-3d-container .carousel-3d-slide {
  padding-top: 50px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slide-frame-position-size {
  position: absolute;
  width: 300px;
  height: 380px;
  z-index: -1;
}

.top-right {
  top: -1%;
  left: 4%;
}

.bottom-right {
  top: -10%;
  left: 8%;
}

.bottom-left {
  top: -1%;
  right: 9%;
}

.pink-slide-frame {
  border: 8px solid var(--pink);
}

.dark-blue-slide-frame {
  border: 8px solid var(--dark-blue);
}

.light-blue-slide-frame {
  border: 8px solid var(--light-blue);
}

.green-slide-frame {
  border: 8px solid var(--green);
}

.yellow-slide-frame {
  border: 8px solid var(--yellow);
}

.slide-content {
  position: relative;
  justify-content: flex-end;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  max-height: 400px;
}

.imgSize {
  width: 300px;
  height: auto;
  max-width: 300px;
}

.imgReg {
  margin-top: -10px;
  align-self: flex-start;
}

.imgUpR {
  margin-top: -38px;
  margin-left: 30px;
}

.imgUpL {
  margin-top: -38px;
  margin-right: 70px;
}

.btn-box-loc-home-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-grow: 1;
}

.btn-box-loc-home {
  font-weight: 600;
  color: var(--black);
  background-color: #ffffff;
  text-decoration: none;
  text-align: start;
}

.home-carousel-wrapper {
  margin-top: 120px;
  margin-bottom: -30px;
}

@media (max-width: 768px) {
  .carousel-3d-container .carousel-3d-slide {
    padding-top: 25px;
  }

  .slide-content {
    justify-content: center;
  }

  .imgUpL {
    margin-top: -38px;
    margin-right: 70px;
  }

  .imgUpR {
    margin-top: -38px;
    margin-left: 30px;
  }

  .imgReg {
    margin-top: -5px;
    margin-left: -20px;
    align-self: flex-start;
  }

  .btn-box-loc-home-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
  }

  .slide-content {
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .top-right {
    top: 4%;
    left: 5%;
  }

  .bottom-right {
    top: -5%;
    left: 8%;
  }

  .bottom-left {
    right: 10%;
    top: 5%;
  }

  .home-carousel-wrapper {
    margin-top: 110px;
    margin-bottom: -10px;
  }
}
</style>