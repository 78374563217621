<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="gray-back top">
      <div class="tech-top-wrap">
        <div class="tech-top-img">
          <img v-if="solution.cover && solution.cover.imageURL" :src="solution.cover.imageURL" />
          <img v-else src="../assets/tech8.jpg" />
        </div>
        <div
          :class="solution.title == 'Delivery-Professional Services' ? 'tech-top-frame blue-line' : 'tech-top-frame'">
          <h1 class="txt54">{{ solution.title }}</h1>
          <div class="lighter" v-html="solution.body"></div>
          <br />
          <a class="btn lowcase" href="../contact"> Get Started</a>
        </div>

        <div class="solution-content-richtxt" v-html="replaceCodeWithExpandLinks(solution.p2)"></div>

        <div class="solutions-schedule-call-wrapper">
          <h2 v-if="solution.footerParagraph" v-html="solution.footerParagraph"></h2>
          <a v-if="solution.footerButton" class="btn lowcase" href="../contact"> {{ solution.footerButton }}</a>
        </div>

      </div>
    </div>
    <div style="color:beige"> {{ this.country }}</div>
    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
//import Testimonials from "@/components/Testimonials.vue";
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
//import Logoscar from "@/components/Logoscar.vue";
import { getCountry, addDynamicStyle } from "../utils/geolocation";
import { addChatWidget } from "../utils/addChatWidget";

export default {
  props: {
    country:String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: this.solution.metaTitle || this.solution.title,
        description: this.solution.metaDescription || this.solution.subTitle,
        image: this.solution.cover.imageURL,
      }),
    };
  },
  components: {
    Topnav,
    Botfooter,
    // Testimonials,
    // Logoscar,
  },
  name: "Technology",
  data() {
    return {
      originCountry: null
    }
  },
  computed: {
    solution() {
      const { slug } = this.$route.params;
      return this.$local.solutions.find((s) => s.slug === slug);
    },
  },
  methods: {
    // replace all <code> sections with divs and links to show more/less
    replaceCodeWithExpandLinks(sourceTxt) {
      if (!sourceTxt)
        return "";
      const readMore = `<a onclick="expandReadMore(this)" class="expand-more">Read More</a>`

      sourceTxt = sourceTxt.replace(/<code>/g, "<span class='expandingDiv'>").replace(/<\/code>/g, `</span>${readMore}`);
      // console.log(sourceTxt);
      return sourceTxt;
    },
    expandReadMore(btn) {
      let expandedText = btn.previousElementSibling;
      if (expandedText.classList.contains("divExpanded")) {
        expandedText.classList.remove("divExpanded");
        btn.classList.remove("expanded");
        btn.textContent = "Read More";
      }
      else {
        expandedText.classList.add("divExpanded");
        btn.classList.add("expanded");
        btn.textContent = "Read Less";
      }

      //debugger // eslint-disable-line
    }
  },
  async mounted() {
    window.expandReadMore = this.expandReadMore;

    let requiresGEOIP = this.solution.requiresGEOIP;
    if (requiresGEOIP) { // get user's country if it's needed in the page
      let country = await getCountry();
      this.originCountry = country || "NA";
      console.log("req data initialized 3");
      addDynamicStyle(country); 
    }

    let isAddChatWidget = this.solution.addChatWidget;
    console.log(`acw=${isAddChatWidget}`);
    if (isAddChatWidget) {
      addChatWidget();
    }
  }

};
</script>

<style>
.expandingDiv {
  transition: all 700ms;
  opacity: 0;
  height: 0;
  overflow: hidden;
  display: block;
}


.divExpanded {
  height: auto;
  transition: all 700ms;
  opacity: 1;
}


.expand-more {
  display: block;
}

.solution-content-richtxt {
  max-width: 1000px;
  margin: 50px auto 70px;
  text-align: center;
}

.solution-content-richtxt blockquote h2 {
  max-width: 700px;
  margin: 50px auto 20px;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.3;
}

.solution-content-richtxt h3 {
  text-align: left;
  clip-path: polygon(0 100%,
      100% 100%,
      100% 85%,
      95% 85%,
      95% 15%,
      100% 15%,
      100% 0,
      0 0);
  border: var(--yellow) 14px solid;
  padding: 30px;
  font-weight: 600;
  font-size: 40px;
  width: 380px;
  line-height: 1.3;
  float: left;
  margin-top: 0;
}

.solution-content-richtxt h4 {
  text-align: left;
  margin: 0px 0 10px 420px;
  line-height: 1.3;
  font-size: 24px;
}

.solution-content-richtxt blockquote {
  max-width: 700px;
  margin: 0px auto -20px;
  font-weight: 400;
}

.solution-content-richtxt p {
  text-align: left;
  margin: 0 0 0 420px;
  padding-bottom: 34px;
  --min-height: 170px;
}

.solution-content-richtxt p.fullwidth {
  margin: auto auto;
  text-align: center;
}

.solutions-schedule-call-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 6rem auto;
}

.solutions-schedule-call-wrapper h2 {
  text-align: center;
}

.solutions-schedule-call-wrapper .btn {
  max-width: 300px;
  margin-top: 20px;
}


.logo {
  max-width: 100px;
}

@media screen and (max-width: 991px) {
  .solution-content-richtxt {
    max-width: 100%;
  }

  .solution-content-richtxt blockquote h2 {
    font-size: 30px;
  }

  .solution-content-richtxt h4 {
    margin: 30px 0 10px 0px;
  }

  .solution-content-richtxt h5 {
    margin: 0px auto 50px;
  }

  .solution-content-richtxt h3 {
    float: unset;
    text-align: center;
    padding: 25px 0;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    clip-path: polygon(100% 85%,
        95% 85%,
        95% 15%,
        100% 15%,
        100% 0%,
        0% 0%,
        0% 15%,
        5% 15%,
        5% 85%,
        0% 85%,
        0% 100%,
        100% 100%)
  }

  .solution-content-richtxt p:not(.israelOnly):not(.restOfWorld) {
    margin: 0px;
    display: block;
  }

}
</style>
